import ReactGA from 'react-ga4'
import EmploymentTypes from '../components/profile/EmploymentTypes'
import Months from '../components/profile/Months.json'

export const SENIORITIES_LIST = ['Junior', 'Mid-Level', 'Senior']

export const SENIORITIES = [
    {
        value: '',
        label: '-',
    },{
        value: 'Junior',
        label: 'Junior',
    },{
        value: 'Mid-Level',
        label: 'Mid-Level',
    },{
        value: 'Senior',
        label: 'Senior',
    }
]

export const CLOSED_INTERVIEW_STATUSSES = ['They said no', "I said no", "Just Took Information"]

export const COLORS = [
    {
        light: "#3949ab",
        dark: "#273377"
    },{
        light: "#5e35b1",
        dark: "#41257b"
    },{
        light: "#0097a7",
        dark: "#006974"
    },{
        light: "#ef6c00",
        dark: "#a74b00"
    },{
        light: "#8e24aa",
        dark: "#631976"
    },{
        light: "#d81b60",
        dark: "#971243"
    },{
        light: "#e53935",
        dark: "#a02725"
    },{
        light: "#757575",
        dark: "#515151"
    },{
        light: "#607D8B",
        dark: "#435761"
    },{
        light: "#827717",
        dark: "#5b5310"
    },{
        light: "#689f38",
        dark: "#486f27"
    },{
        light: "#43a047",
        dark: "#2e7031"
    },{
        light: "#00897b",
        dark: "#005f56"
    },{
        light: "#f4511e",
        dark: "#aa3815"
}];

export const HAPPY_COLORS = [
    /*'#ff0000',
    '#00ff00',
    '#0000ff',
    '#ffff00',
    '#ff00ff',
    '#ff6600',
    '#6600ff',
    '#33cc33',
    '#ff3399',
    '#ccff00',
    '#3399ff',
    '#00ffff',
    '#ffcc00',*/

    /*"#9966ff",
    "#0055bc",
    "#00a1c2",
    "#ed6804",
    "#ffcc00",
    "#66ff33",
    "#ff0066",
    "#6600ff",
    "#00ffcc",
    "#ff3366",
    "#33ccff",
    "#ff9933",
    "#cc33ff"

  "#007189",
  "#005163",
  "#004150",
  "#00313d",
  "#005f73",
  "#004e62",
  "#003d51",
  "#7c51cc",
  "#5e3b99",
  "#4f307f",
  "#402566",
  "#4c3380",
  "#331c50"*/

    '#B19CD9',
    '#FFFFFF',
    '#A8A8A8',
    '#4B0082',
    '#E0E0E0',
    '#F0E6FA',
    '#8E4585',
    '#9370DB',
    '#333333',
    '#666666',
    '#734F96',
    '#660066',
    '#D8BFD8'
]

export const ROWS_PER_PAGE = 30
export const NO_COMPANY = 'Undisclosed Company'
export const NO_LOCATION_TEXT = 'Israel'
export const DEF_LAT = 32.109333
export const DEF_LNG = 34.855499
export const ALLOWED_HIST_TYPES = ['jobs', 'resumes', 'myJobs', 'profile', 'activity', 'reports', 'saveditems', 'main', 'companies', 'jobhuntUsers'];

export const CANDIDATE_ACTIVITY_SUB_TYPES = ['myApplications', 'jobsSuggestion', 'matches']
export const RECRUITER_ACTIVITY_SUB_TYPES = ['myCandidates', 'applicants', 'matches']
export const REMOVE_AFTER_APPLY_SUB_TYPE = ['jobsSuggestion', 'applicants']

export const CANDIDATE_ACTIVITY_TABS = ['My Applications', 'Jobs Suggestion', 'Matches']
export const RECRUITER_ACTIVITY_TABS = ['My Candidates', 'Applicants', 'Matches']

export const PESONAL_JOURNEY_STEPS = [
    'Title + Details',
    'Cover Letter',
    'CV',
    'Exclude + Details',
    'Test Email',
    'Publish'
]
export const PESONAL_JOURNEY_STEPS_NAMES = [
    'TitleDetails',
    'CoverLetter',
    'CV',
    'ExcludeDetails',
    'TestEmail',
    'Publish'
]
export const PUBLISH_STEP_FILTERS = ['approve needed - new cycle', 'approve needed - exists cycle', 'publishing - auto', 'publishing - manual']

export const FIELD_TYPES = {
    TEXT: 'TEXT',
    SELECT: 'SELECT',
    LOCATION: 'LOCATION',
    MULTILINE: 'MULTILINE',
    DATE: 'DATE',
    DATE2: 'DATE2',
    DATE_TIME: 'DATE_TIME',
    CHECK_BOX: 'CHECK_BOX',
    RADIO: 'RADIO',
    RADIO_ICONS: 'RADIO_ICONS',
    CHECK_BOX_GROUP: 'CHECK_BOX_GROUP',
    CASH: 'CASH',
    TITLE: 'TITLE'
}

export function displayJourneyStatus(status){
    switch (status) {
        case 'freezed':
            return 'account frozen'
        default:
            return status;
    }
}

export function getHost(){
    if (window.location.hostname === 'localhost'){
        return 'http://localhost:5000';
    } else {
        return window.location.protocol + '//' + window.location.hostname;
    }
}

export function isHebrewText(text){
    if(!text){
        return false
    }
    const hebrew = text.search(/[\u0590-\u05FF]+/mgi);

      return (hebrew >= 0)? true : false;
}

export function getNameFromEmail(email){
    if (!email){
        return ''
    }
    const epos = email.indexOf("@")
    if (epos === -1){
        return capitalize(email)
    }

    return capitalize(email.substring(0, epos) || '')
}

export function getMyJobs(jobs){
    return jobs.filter(result => result.jobstatus === 'A').map(function(item) { 
        return {
            _id:item._id, 
            title:item.title, 
            tags:item.tags, 
            salaryMin:item.salaryMin, 
            company:item.company, 
            seniorityLevel:item.seniorityLevel, 
            phone:item.phone
        }; 
    });
}

export function gaPageView(path, title, queryParams){
    let pathWithQueryParams = (queryParams) ? path + queryParams : path
    /*ReactGA.set({ page: pathWithQueryParams })
    ReactGA.set({ title: title })
    ReactGA.pageview(pathWithQueryParams)*/
    ReactGA.send({ hitType: "pageview", page: pathWithQueryParams, title: title });
}

export function getMenuObjects(isAuthed, isRecruiter, isJobHunt){
    let allowedMenus = [];
    let allowedValues = []; 
    
    if (isJobHunt){
        allowedMenus.push({label:'Dashboard', value:''})
        allowedValues.push('')

        allowedMenus.push({label:'My Journey', value:'personal-journey'})
        allowedValues.push('personal-journey')

        allowedMenus.push({label:'Journey - Table View', value:'Journey-table-view'})
        allowedValues.push('Journey-table-view')
    } else {
        if (!isAuthed || !isRecruiter){
            allowedMenus.push({label:'Jobs', value:'jobs'})
            allowedValues.push('jobs')
        }
        if (!isAuthed || isRecruiter){
            allowedMenus.push({label:'Resumes', value:'resumes'})
            allowedValues.push('resumes')
        }
        if(isAuthed){
            allowedMenus.push({label:'Activity', value:'activity'})
            allowedValues.push('activity')
        }
        //allowedMenus.push({label:'Companies', value:'companies'})
        //allowedValues.push('companies')
        if (isRecruiter){
            allowedMenus.push({label:'My Jobs', value:'myJobs'})
            allowedValues.push('myJobs')
        }
    }
    return {allowedMenus, allowedValues}
}

/*
    lat1, lon1: The Latitude and Longitude of point 1 (in decimal degrees)
    lat2, lon2: The Latitude and Longitude of point 2 (in decimal degrees)
    unit: The unit of measurement in which to calculate the results where:
    'M' is statute miles (default)
    'K' is kilometers
    'N' is nautical miles
*/
export function distance(lat1, lon1, lat2, lon2, unit) {
    let radlat1 = Math.PI * lat1/180
    let radlat2 = Math.PI * lat2/180
    //var radlon1 = Math.PI * lon1/180
    //var radlon2 = Math.PI * lon2/180
    let theta = lon1-lon2
    let radtheta = Math.PI * theta/180
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180/Math.PI
    dist = dist * 60 * 1.1515
    if (unit==="K") { dist = dist * 1.609344 }
    if (unit==="N") { dist = dist * 0.8684 }

    return dist
}

export function getDiffInSecondsString(string){
    const DAY_IN_SECONDS = 86400;
    const DIFFS = {'Past day':DAY_IN_SECONDS*2, 'Past 3 days':DAY_IN_SECONDS*4, 'Past week':DAY_IN_SECONDS*8, 'Past month':DAY_IN_SECONDS*31}

    return (DIFFS[string]) ? DIFFS[string] : 0;
}

export function getDiffInSeconds(date, endDate){
    let d1 = endDate ? new Date(endDate) : new Date();
    let d2 = new Date(date);

    return Math.abs(d2-d1)/1000;
}

export function getDiffInDays(date, endDate){
    let d1 = endDate ? new Date(endDate) : new Date();
    let d2 = new Date(date);

    return Math.abs(d2-d1)/86400000;
}

export function displayDate(dateParam){
    if (!dateParam){
        return '';
    }

    const dateObj = new Date(dateParam.substr(0, 16))
    if (!dateObj){
        return '';
    }

    return dateObj.getDate() + '/' + parseInt(dateObj.getMonth() + 1) + '/' + dateObj.getFullYear() + ' - ' + dateObj.getHours() + ':' + dateObj.getMinutes()
}

export function getDateText(date) {
    if (!date){
        return 'now';
    }

    const TIME_PERIODS = [{numOfSeconds:60, alonePattern:'1 minute ago', manyPattern:'{num} minutes ago'},
                        {numOfSeconds:3600, alonePattern:'1 hour ago', manyPattern:'{num} hours ago'},
                        {numOfSeconds:86400, alonePattern:'1 day ago', manyPattern:'{num} days ago'},
                        {numOfSeconds:86400 * 7, alonePattern:'1 week ago', manyPattern:'{num} weeks ago'},
                        {numOfSeconds:86400 * 30, alonePattern:'1 month ago', manyPattern:'{num} months ago'},
                        {numOfSeconds:86400 * 365, alonePattern:'1 year ago', manyPattern:'{num} years ago'}];

    var dateText = '';

    let diff = getDiffInSeconds(date);

    if( diff < TIME_PERIODS[0].numOfSeconds ){
        dateText = 'now'
    }

    for (var i = 0; !dateText && i < TIME_PERIODS.length - 1; i++){
        if( diff < TIME_PERIODS[i + 1].numOfSeconds ){
            var period = Math.floor( diff / TIME_PERIODS[i].numOfSeconds );
            //var period = (diff / TIME_PERIODS[i].numOfSeconds).toFixed(1);
            dateText = (period === 1) ? TIME_PERIODS[i].alonePattern : TIME_PERIODS[i].manyPattern.replace('{num}', period);
            break;
        }
    }

    if(!dateText){
        period = Math.floor( diff / TIME_PERIODS[i].numOfSeconds );
        dateText = (period === 1) ? TIME_PERIODS[i].alonePattern : TIME_PERIODS[i].manyPattern.replace('{num}', period)
    }

    return dateText;
}
export function getDiffInCycles(date){
    if (!date){
        return 0;
    }

    return Math.floor(getDiffInSeconds(date) / (86400 * 7 * 2));
}

export function getPeriodText(params) {
    const {date, endDate, toWeeks} = params
    if (!date){
        return 'now';
    }

    const TIME_PERIODS = toWeeks? [
        {numOfSeconds:60, alonePattern:'1 minute', manyPattern:'{num} minutes'},
        {numOfSeconds:3600, alonePattern:'1 hour', manyPattern:'{num} hours'},
        {numOfSeconds:86400, alonePattern:'1 day', manyPattern:'{num} days'},
        {numOfSeconds:86400 * 7, alonePattern:'1 week', manyPattern:'{num} weeks'},
    ]:[
        {numOfSeconds:60, alonePattern:'1 minute', manyPattern:'{num} minutes'},
        {numOfSeconds:3600, alonePattern:'1 hour', manyPattern:'{num} hours'},
        {numOfSeconds:86400, alonePattern:'1 day', manyPattern:'{num} days'},
        {numOfSeconds:86400 * 7, alonePattern:'1 week', manyPattern:'{num} weeks'},
        {numOfSeconds:86400 * 30, alonePattern:'1 month', manyPattern:'{num} months'},
        {numOfSeconds:86400 * 365, alonePattern:'1 year', manyPattern:'{num} years'}
    ];

    var dateText = '';

    let diff = getDiffInSeconds(date, endDate);

    if( diff < TIME_PERIODS[0].numOfSeconds ){
        dateText = 'now'
    }

    for (var i = 0; !dateText && i < TIME_PERIODS.length - 1; i++){
        if( diff < TIME_PERIODS[i + 1].numOfSeconds ){
            var period = +parseFloat(diff / TIME_PERIODS[i].numOfSeconds).toFixed(2);
            //var period = (diff / TIME_PERIODS[i].numOfSeconds).toFixed(1);
            dateText = (period === 1) ? TIME_PERIODS[i].alonePattern : TIME_PERIODS[i].manyPattern.replace('{num}', period);
            break;
        }
    }

    if(!dateText){
        period = Math.floor( diff / TIME_PERIODS[i].numOfSeconds );
        dateText = (period === 1) ? TIME_PERIODS[i].alonePattern : TIME_PERIODS[i].manyPattern.replace('{num}', period)
    }

    return dateText;
}

export function getHebrewDateText(date) {
    if (!date){
        return 'עכשיו';
    }

    const TIME_PERIODS = [{numOfSeconds:60, alonePattern:'לפני דקה', doublePattern:'לפני 2 דקות', manyPattern:'לפני {num} דקות'},
                        {numOfSeconds:3600, alonePattern:'לפני שעה', doublePattern:'לפני שעתיים', manyPattern:'לפני {num} שעות'},
                        {numOfSeconds:86400, alonePattern:'אתמול', doublePattern:'שלשום', manyPattern:'לפני {num} ימים'},
                        {numOfSeconds:86400 * 7, alonePattern:'לפני שבוע', doublePattern:'לפני שבועיים', manyPattern:'לפני {num} שבועות'},
                        {numOfSeconds:86400 * 30, alonePattern:'לפני חודש', doublePattern:'לפני חודשיים', manyPattern:'לפני {num} חודשים'},
                        {numOfSeconds:86400 * 365, alonePattern:'לפני שנה', doublePattern:'לפני שנתיים', manyPattern:'לפני {num} שנים'}];

    var dateText = '';

    let diff = getDiffInSeconds(date);

    if( diff < TIME_PERIODS[0].numOfSeconds ){
        dateText = 'עכשיו'
    }

    for (var i = 0; !dateText && i < TIME_PERIODS.length - 1; i++){
        if( diff < TIME_PERIODS[i + 1].numOfSeconds ){
            var period = Math.floor( diff / TIME_PERIODS[i].numOfSeconds );
            //var period = (diff / TIME_PERIODS[i].numOfSeconds).toFixed(1);
            
            //dateText = (period === 1) ? TIME_PERIODS[i].alonePattern : TIME_PERIODS[i].manyPattern.replace('{num}', period);
            if(period === 1){
                dateText = TIME_PERIODS[i].alonePattern
            } else if(period === 2){
                dateText = TIME_PERIODS[i].doublePattern
            } else {
                dateText = TIME_PERIODS[i].manyPattern.replace('{num}', period)
            }
            break;
        }
    }

    if(!dateText){
        period = Math.floor( diff / TIME_PERIODS[i].numOfSeconds );
        //dateText = (period === 1) ? TIME_PERIODS[i].alonePattern : TIME_PERIODS[i].manyPattern.replace('{num}', period)
        if(period === 1){
            dateText = TIME_PERIODS[i].alonePattern
        } else if(period === 2){
            dateText = TIME_PERIODS[i].doublePattern
        } else {
            dateText = TIME_PERIODS[i].manyPattern.replace('{num}', period)
        }
    }

    return dateText;
}

export function getCompanyText(company) {
    return (!company || company === 'N/A') ? NO_COMPANY : company || NO_COMPANY;
}

export function getemploymentTypeText(employmentType) {
    const employmentTypeObject = EmploymentTypes.find(employmentTypeO => employmentTypeO.value === employmentType);  
    return (employmentTypeObject && employmentTypeObject.label) ? employmentTypeObject.label : "Full-time";
}

export function getlocationText(location) {
    return (!location || location === "N/A") ? NO_LOCATION_TEXT : location || NO_LOCATION_TEXT;
}

export function filterUrl(url){
    let newUrl = url.replace(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/mgi, " ");
    newUrl = newUrl.replace(/["’–|~*◈<>=\\!$►/^@ ()?+.#%&':]/mgi, '-');
    newUrl = newUrl.replace(/[-]{2,}/mgi, '-');

    return newUrl;
}

export function getJobRoute(jobId, jobTitle, company){
    let urlText = ''
    if(jobTitle){
        urlText = urlText + jobTitle + '-'
    }
    if(company && company !== 'N/A' && urlText.toLowerCase().indexOf(company.toLowerCase()) === -1){
        urlText = urlText + company + '-'
    }
    urlText = urlText + jobId
    urlText = '/jobs/' + filterUrl(urlText.toLocaleLowerCase())
    return urlText
    //return '/jobs/' + filterUrl(jobTitle + '-' + jobId)
    //return '/jobs/' + jobId
}

export function getHistogramTypeLabel(histogramType){
    if (histogramType === 'jobs'){
        return 'דרושים'
    } else if (histogramType === 'resumes'){
        return 'גיוס עובדים'
    } else {
        return histogramType
    }
}

export function getRusumeRoute(id, firstName, title){
    let urlText = ''
    if(firstName){
        urlText = urlText + firstName + '-'
    }
    if(title){
        urlText = urlText + title + '-'
    }
    urlText = urlText + id
    urlText = '/resumes/' + filterUrl(urlText.toLocaleLowerCase())
    return urlText
}

export function randomColor() {
    return COLORS[Math.floor(Math.random() * COLORS.length)];
}

export function getRandom(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }

export function cleanHiddenChars(string) {
    if (!string){
        return ''; 
    }

    // eslint-disable-next-line no-control-regex
    return string.trim().replace(/[\u200B\u200C\u200D\u200E\u200F\u202A\u202B\u202D\u202E\u202C\u2060\u2061\u2063\u2064]/mgi, '');
}

export function getColorFromString (string){
    if (!string){
        return COLORS[0]; 
    }

    // Calculate a number based on the ASCII values of the characters in the name
    let number = 0;
    for (let i = 0; i < string.length; i++) {
        number += string.charCodeAt(0);
    }

    // Use the calculated number to select a color from the list
    const index = number % COLORS.length;
    
    return COLORS[index];
}

export function getColorFromString2 (string){
    if (!string){
        return HAPPY_COLORS[0]; 
    }

    // Calculate a number based on the ASCII values of the characters in the name
    let number = 0;
    for (let i = 0; i < string.length; i++) {
        number += string.charCodeAt(0);
    }

    // Use the calculated number to select a color from the list
    const index = number % HAPPY_COLORS.length;
    
    return HAPPY_COLORS[index];
}

export function getInterviewTypeColor (interviewType){
    if (!interviewType){
        return HAPPY_COLORS[0]; 
    }

    const INTERVIW_TYPES = {
        'Recruiter Call (Introduction)':{color: '#B19CD9'},
        'Self Submitted Application':{color: '#FFFFFF'},
        'Incoming Email (First contact)':{color: '#A8A8A8'},
        'Recruiter Call (Follow up)':{color: '#4B0082'},
        'Screening Interview':{color: '#E0E0E0'},
        'HR Interview':{color: '#F0E6FA'},
        'Professional Interview':{color: '#8E4585'},
        'Hiring Manager Interview':{color: '#9370DB'},
        'Diagnostic Interview (מכון אבחון)':{color: '#333333'},
        'Case Interview':{color: '#666666'},
        'Presentation Interview':{color: '#734F96'},
        'Technical Interview':{color: '#660066'},
        'Assignment':{color: '#D8BFD8'}
    }
    /*const INTERVIW_TYPES = [
        {label:`Recruiter Call (Introduction)`},
        {label:`Self Submitted Application`},
        {label:`Incoming Email (First contact)`},
        {label:`Recruiter Call (Follow up)`},
        {label:`Screening Interview`},
        {label:`HR Interview`},
        {label:`Professional Interview`},
        {label:`Hiring Manager Interview`},
        {label:`Diagnostic Interview (מכון אבחון)`},
        {label:`Case Interview`},
        {label:`Presentation Interview`},
        {label:`Technical Interview`},
        {label:`Assignment`}
    ]*/

    /*const index = INTERVIW_TYPES.findIndex(option => option.label === interviewType)

    if (index !== -1){
        return HAPPY_COLORS[index]
    } else {
        return getColorFromString2(interviewType)
    }*/
    if (INTERVIW_TYPES[interviewType]){
        return INTERVIW_TYPES[interviewType].color
    } else {
        return getColorFromString2(interviewType)
    }
}

export function pushShareGaEvent(histogramType, action, from) {
    let category
    let gaAction
    let pos=''

    switch(histogramType){
        case 'jobs':{
            category = 'Card';
            pos = '_job_'
            break;
        }
        case 'resumes':{
            category = 'Card';
            pos = '_resume_'
            break;
        }
        case 'myJobs':{
            category = 'Job_manager';
            break;
        }
        default:{}
    }

    switch(action){
        case 'show':{
            gaAction = 'show' + pos + 'shareDialog';
            break;
        }
        case 'close':{
            gaAction = 'close' + pos + 'ShareDialog__close_icon';
            break;
        }
        case 'socialClose':{
            if (from){
                gaAction = 'close' + pos + 'ShareDialog__social_button_' + from;
            }
            break;
        }
        case 'copyUrl':{
            gaAction = 'CopyURL_Button_click';
            break;
        }
        default:{}
    }

    if (category && gaAction){
        ReactGA.event({category,action:gaAction})
    }
}

export function getNameLetters(firstName, lastName){
    let letters = ''
    if(firstName || lastName){
        if (firstName){
            letters = letters + firstName.charAt(0).toUpperCase()
        }
        if (lastName){
            letters = letters + lastName.charAt(0).toUpperCase()
        }
    } else {
        letters = 'U'
    }

    return letters
}

export function validUrl(url){
    if (!url){
        return url
    }

    if (url.indexOf("http://") === -1 && url.indexOf("https://") === -1) {
        return "http://" + url
    } else {
        return url
    }
}

export function capitalize(str) {
    if (!str){
        return ''
    }

    if(str !== 'string' && !str instanceof String){
        return str
    }
    str = str.trim()
    
    str = str.split(" ");

    for (var i = 0, x = str.length; i < x; i++) {
        if(str[i] && str[i][0]){
            str[i] = str[i][0].toUpperCase() + str[i].substr(1);
        }
    }

    return str.join(" ");
}

export function capitalizeArray(array){
    array = array || []

    return array.map(str=> {
        str = str.toLowerCase()
        return capitalize(str)})
}


export function getRedirectPath(pathname, histogramType, objectId) {
    return (['jobs', 'resumes'].includes(histogramType) ? ('/' + histogramType + ((objectId)?('/'+objectId):'')) : pathname)
}

export function isAgency(company) {
    if (!company){
        return false
    }
    company = company.trim().toLowerCase()
    const AGENCIES = [
        'experis','manpower','ness-tech','taldor','maof-group','edanel','shavit-soft','esr','manpower-cv','dialog','l-b','unilink','medulla','amanet','svt','ethosia','hrhome','memad3','unitask-inc','jbh','gtech','gius360','octally','cvwebmail','wojo','tcmcareer','sqlink','wdc','top-soft','nisha','bynetos','etgar-hr','ors','morejobs','hever','korentec','atidbyisum','elad','b-e','aman','halufa','gohire','log-on','ingima','actiview','hy-p','herolo','gefenhasamot','yaelsoft','matrix','tigbur','matrixbi','elegance','cps','setteam','indigoil','elevation','prologic','shemeshhr','gim','zoominfo','mindadmedia','cvpool','adam-milo','saritkrul','milgam','bynetsoft','orantech','apm','ofakim-group','ellers','nextleader','xtra-mile','lr-job','lvpjobs','gemel','mindu','smokijobs','msg','abhrcv','grsee','ironm','am-projects','entrypoint','conforti','ardix','codeoasis','razorgrip','nishapro','sourcing','theworldlead','kluren','elipse-eng','nethr','jobshop','bpsoft','hito','strike','mvp-hr','job-place','horizontech','comblack','m-hr','hrsystems','noaa','rs-hr','asorman','malam','acs','tadiad','2work','enosh','capsula-hr','jobswipe','perfectahr','sapir placement company','Israeldefense','betheone','msbitsoftware','top-group','avivim-hr','tafkid-plus','hrmiracle','newton-outsourcing','opal-hr','dganit-hr','hilan','matchpointit','erelhr','israelhayom','pitronot-hr','Galshan','freshpaint','korentext','gal-hr','talos-hr','itworks','sabress','amitim-hr','peoplecom','go-job','recruitil','michalinks',
        'urbanrecruits','gotfriends','muza-hr','next-point','d-hr','banani-hr','towork','מ.ש השמה','recruitx','keshet-hr','stepup-hr','salina-hr','copula-hr','alternativa-hr','hr-pro','leader-hr','ortal-hr','totally-hr','red c h.r'
    ]
    return AGENCIES.includes(company)
}

export function getJobBadge(object, type, expired=false){
    if (!object){
        return {badge:'', badgeDesc:''}
    }

    const DAY_IN_SECONDS = 86400
    if (type === 'profile'){
        /*const applies = (object.applies) ? object.applies : []
        const applied = (object.applied) ? object.applied : []

        if (DAY_IN_SECONDS * 2 > getDiffInSeconds(object.datecreated)){
            return {badge:'New', badgeDesc:'Get ahead of others. Connect now.'}
        } else {
            return {badge:'', badgeDesc:''}
        }*/
        return {badge:'', badgeDesc:''}
    } else if (type === 'job'){
        const company = getCompanyText(object.company)

        const hiring = (object.source === 'anon_fb') ? (object.loggedinfrom === undefined || object.loggedinfrom === "false") ? false : true : true

        const applies = (object.applies && object.applies.length <= 20) ? 2 * object.applies.length : (object.applies) ? object.applies.length : 0

        let dataMonthAgo = new Date();
        dataMonthAgo.setMonth(dataMonthAgo.getMonth() - 1);
        dataMonthAgo.setHours(0, 0, 0);
        dataMonthAgo.setMilliseconds(0);

        const watchesLastMonth = ((object.watches)?object.watches.filter(watch => new Date(watch.date) >= dataMonthAgo):[]).length
        let badgePercentHiring 

        if (watchesLastMonth >= 90){
            badgePercentHiring = 96
        } else if (watchesLastMonth >= 50){
            badgePercentHiring = 92
        } else if (watchesLastMonth >= 20){
            badgePercentHiring = 83
        } else {
            badgePercentHiring = 67
        }

        if(expired){
            return {badge:'Expired', badgeDesc:`This job is no longer available.`, badgePercentHiring}
        } else if (object.applies && object.applies.length >= 5){
            return {badge:'Hot', badgeDesc:applies + ' people are looking at this job. Apply now.'}
        } else if (DAY_IN_SECONDS * 2 > getDiffInSeconds(object.datecreated)){
            return {badge:'New', badgeDesc:'Get ahead of others. Apply now.'}
        } else if (hiring){
            return {badge:`We're Hiring`, badgeDesc:((company === NO_COMPANY)?'' : company + ' is ') + 'actively hiring on JobSwipe'}
        } else {
            return {badge:'', badgeDesc:''}
        }
    } else {
        return {badge:'', badgeDesc:''}
    }
}

export function mergeJobsTags(jobs) {
    
    let jointArray = []

    jobs.forEach(job => {
        jointArray = [...jointArray, ...(job.tags || []) ]
    })

    return removeDuplicate(jointArray)
}

export function removeDuplicate(array){
    array = array || []

    return array.filter((item,index) => array.indexOf(item) === index)
}

export function getTitleDescription(lookingforwork, histogramType){
    const jobsText = {
        title: 'JobSwipe Job Search | Your Dream Job Is 1 Click Away',
        description: "Search for jobs in your area using JobSwipe's smart job search engine - the best way to find a job. Apply with just 1 click."
    }

    const resumesText = {
        title: 'JobSwipe Resume Search | Your Dream Talent Is 1 Click Away',
        description: "Search for talents in your area using JobSwipe's smart resume search engine - the best way to find a talent. Connect with just 1 click."
    }

    if (histogramType){
        switch (histogramType){
            case 'jobs':{
                return jobsText
            } case 'resumes':{
                return resumesText
            } default:{
                
            }
        }
    }

    if (lookingforwork !== undefined && lookingforwork !== null){
        if (lookingforwork){
            return jobsText
        } else {
            return resumesText
        }
    }
    
    return jobsText
}

export function getMetaDescription() {
    var metas = document.getElementsByTagName('meta');
    for(var i in metas) {
        if (typeof(metas[i].name) !== 'undefined' && metas[i].name.toLowerCase() === "description") {
            return metas[i].content;
        }   
    }
    return '';
}

export function getLastYears(numOfYears){
    let years = []
    const now = new Date();
    const currYear = now.getFullYear();

    for (let i=0; i<numOfYears;i++){
        years.push(currYear - i)
    }

    return years
}

export function getCompanyUrl(urls){
    if (!urls || urls.length === 0){
        return ''
    }

    if (urls.length === 1){
        return urls[0].url
    }

    let biggestCont = 0
    let url
    urls.forEach(urlObject => {
        if (urlObject.count && urlObject.url && urlObject.count > biggestCont){
            biggestCont = urlObject.count
            url = urlObject.url
        }
    })

    return url
}

export function getDateStringToShow(date){
    if (!date){
        return ''
    }

    return date.substring(0, 10)
}

export function getNextDayOfWeek(date, dayOfWeek){
    var resultDate = new Date(date.getTime());
    resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);
    return resultDate;
}

export function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function dateAsString(date){
    if (date instanceof Date && !isNaN(date)) {
        return date.getDate()+'.'+(date.getMonth()+1)+'.'+date.getFullYear()
    } else {
        return ''
    }
}

export function getDateString(data){
    if (!data){
        return ''
    }

    if (data.month && data.year) {
        return getMonthLabel(data.month) + ' ' + data.year
    } else if (data.year) {
        return data.year
    } else {
        return ''
    }
}

export function getDatesString(data){
    if (!data){
        return ''
    }

    let text = '';

    text = getMonthLabel(data.startMonth) + ' ' + data.startYear + ' - '

    if (data.endMonth && data.endYear) {
        text = text + getMonthLabel(data.endMonth) + ' ' + data.endYear
    } else {
        text = text + 'present'
    }

    return text;
}

export function getInternationalPhone(phone){
    let internationalPhone = phone || ''

    internationalPhone = internationalPhone.replace(/[^0-9+]/g, '');
    /*internationalPhone = internationalPhone.replace(/-/mgi, '');
    internationalPhone = internationalPhone.replace(/ /mgi, '');*/
    
    if(internationalPhone.length > 5 && internationalPhone[0] === '5'){
        internationalPhone = '0' + internationalPhone
    }
    if(internationalPhone.length > 5 && internationalPhone[0] === '0'){
        internationalPhone = '+972' + internationalPhone.substr(1)
    }

    return internationalPhone
}

function getMonthLabel(month){
    if (month){
        return Months[month - 1].label
    }
    return ''
}

export function showDateTimeFromString(date, showTime=true, weekShow=false){
    if (!date){
        return ''
    }

    const year = date.substring(0, 4)
    const month = date.substring(5, 7)
    const day = date.substring(8, 10)
    const hour = date.substring(11, 13)
    const minute = date.substring(14, 16)

    if (showTime){
        return `${day}.${month}.${year} ${hour}:${minute}`
    } else if (weekShow){
        return `${day}.${month} W`
    } else {
        return `${day}.${month}.${year}`
    }
}

export function displayCash(params){
    if (params.value === undefined || params.value === null || params.value === ''){
        return ''
    }

    let returnValue = params.value || ''

    if(returnValue){
        returnValue = returnValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    //if (params.currency){
        const currenciesLabels = {'ILS':'₪', 'USD':'$', 'EUR':'€', 'GBP':'£'}
        returnValue += '' + (currenciesLabels[params.currency] || '₪')
    //}

    //if (params.frequency){
        //const frequenciesLabels = {'HOUR':'Hourly', 'MONTH':'Monthly', 'YEAR':'Annual'}
        const frequenciesLabels = {'HOUR':'/hour', 'MONTH':'/month', 'YEAR':'/year'}
        returnValue += '' + (frequenciesLabels[params.frequency] || '/month')
    //}

    return returnValue
}

export function displayPosition(number){
    if (!number || isNaN(number)){
        return ''
    }

    switch (number){
        case 1:{
            return '1st'
        } case 2:{
            return '2nd'
        } case 3:{
            return '3rd'
        } default: {
            return number.toString() + 'th'
        }
    }
}

export function getTodayString() {
    return new Date().toISOString().substring(0, 10)
}

export function downloadCSV(csvContent, fileName) {
    const link = document.createElement('a')
    link.setAttribute('download', fileName)
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent));
    document.body.appendChild(link)
    link.click()
    link.remove()
}

export function getCSVContent(cells, data) {
    let csvContent = "";

    csvContent = ""//"data:text/csv;charset=utf-8,";
    let first = true

    cells.forEach(function(cell) {
        if(first){
            first = false
        } else {
            csvContent += ','
        }
        csvContent += cell.label
    });
    csvContent += '\n'

    data.forEach(function(row) {
        first = true
        cells.forEach(function(cell) {
            if(first){
                first = false
            } else {
                csvContent += ','
            }
            let value = (row[cell.id] !== undefined) ? row[cell.id] : ''

            if(cell.getValue){
                value = cell.getValue(row)
            }

            if(cell.capitalize){
                value = capitalize(value)
            }

            switch (cell.type){
                case 'DATE': {
                    value = showDateTimeFromString(value, false);
                    break;
                } case 'BOOLEAN': {
                    if(row[cell.id] === undefined){
                        value = ''
                    } else {
                        value = row[cell.id]?'Yes':'No'
                    }
                    break;
                } case 'DATE_TIME': {
                    value = showDateTimeFromString(value, true);
                    break;
                } case 'CONTACT': {
                    value = value + (row[cell.phone]?(', ' + row[cell.phone]):'') + (row[cell.email]?(', ' + row[cell.email]):'')
                    break;
                } case 'SALARY': {
                    value = displayCash({value:value, currency:row[cell.curField], frequency:row[cell.freqField]})
                    break;
                } case 'NAME_FROM_EMAIL': {
                    value = getNameFromEmail(value)
                    break;
                } case 'CYCLES': {
                    if (!row[cell.fieldToCheck]){
                        if(cell.fieldAlternative){
                            value = row[cell.fieldAlternative] || ''
                        } else {
                            value = ''
                        }
                    }
                    break;
                } default:{
                    
                }
            }

            if (typeof value === 'string' || value instanceof String){
                value = (value||'').replaceAll('"', '""')
            }
            csvContent += '"' + value + '"'
        });
        csvContent += '\n'
    });

    return csvContent
}

export function getDaysToNextSubscriptionPay(subscriptionStart){
    if(!subscriptionStart){
        return false;
    }

    //subscriptionStart = new Date(2024, 5, 8, 16, 59, 0, 0);
    //console.log(subscriptionStart);
    subscriptionStart = new Date(subscriptionStart);

    //console.log(subscriptionStart);
    //return 27;
        
        var oneHour = 1000 * 60 * 60;
        var oneDay = oneHour * 24;
        var todayDateTime = new Date();
        var startDateTime = subscriptionStart;
        var payInCurrentMonth = new Date(todayDateTime.getTime());
        
        payInCurrentMonth.setDate(startDateTime.getDate());
        payInCurrentMonth.setHours(startDateTime.getHours());
        payInCurrentMonth.setMinutes(startDateTime.getMinutes());
        payInCurrentMonth.setSeconds(startDateTime.getSeconds());

        var diff = todayDateTime.getTime() - payInCurrentMonth.getTime();
        if(diff > 0){
        // payment already done, new payment in next month
            payInCurrentMonth.setMonth(payInCurrentMonth.getMonth() + 1);
            diff = todayDateTime.getTime() - payInCurrentMonth.getTime();
        }
        // not payment yet
        diff = Math.abs(diff);
        return parseInt(diff / oneDay);
}

export function getDaysFromHistory (history, fromDate){
    if (!history || history.length === 0){
        return 0
    }

    var days = 0

    history.forEach(historyObject => {
        if (historyObject.start && historyObject.start >= fromDate){
            days += getDiffInDays(historyObject.start, historyObject.end)
        }
    });

    return days
}

export function getDeffollowUpReminderDate () {
    let date = new Date();
        //date.setMonth(date.getMonth()+1)
        date.setDate(date.getDate()+7)
        date.setHours(10)
        date.setMinutes(0)
        let deffollowUpReminderDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();

        return deffollowUpReminderDate.substring(0, 16)
}
